import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import AnimateItem from '@/components/atoms/AnimateItem/AnimateItem';
import ContentfulImage from '@/components/atoms/ContentfulImage/ContentfulImage';
import Heading from '@/components/atoms/Heading/Heading';
import Quote from '@/components/molecules/Quote/Quote';
import { ContainerProps } from '@/lib/dataSource/contentful/containers';
import { anchorTarget } from '@/lib/utils/anchorTarget';
import { addHttps } from '@/lib/utils/stringReplace/addHttps';
import youtubeParser from '@/lib/utils/youtubeParser';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

/**
 * The RichTextContainer component renders rich text field from contentful
 *
 * @param {object} props - RichTextContainerProps
 * @returns {React.ReactNode} - RichTextContainer
 */
export const RichTextContainer = (
  props: ContainerProps['richText'] & {
    /** The optional className */
    className?: string;
  }
) => {
  const {
    columnsTablet,
    columnsDesktop,
    useContainer,
    content,
    customClasses,
  } = props.fields;

  return (
    <div
      data-testid="rich-text-container"
      className={clsx(
        useContainer && 'page-max-w',
        columnsTablet && 'sm:grid sm:grid-cols-6 sm:gap-6',
        columnsDesktop && 'md:grid md:grid-cols-12 md:gap-8'
      )}
    >
      <div
        data-testid="rich-text-columns"
        className={twMerge(
          'prose-blockquote:border-0 font-petco leading-6 prose-li:p-0 prose-li:m-0 prose-li:marker:text-neutral-800 prose prose-h1:mb-6 prose-h2:mb-5 prose-h2:mt-0 prose-h3:mb-4 max-w-none',
          props.className,
          customClasses,
          clsx(
            columnsTablet &&
              `sm:col-span-${columnsTablet} sm:col-start-${
                (6 - columnsTablet) / 2 + 1
              }`,
            columnsDesktop &&
              `md:col-span-${columnsDesktop} md:col-start-${
                (12 - columnsDesktop) / 2 + 1
              }`
          )
        )}
      >
        {documentToReactComponents(content, {
          renderNode: {
            /**
             * @param {object} _ - Node Data
             * @param {React.ReactNode} children - Node Children Tree
             * @returns {React.ReactNode} - ReactNode
             */
            [BLOCKS.HEADING_1]: (_, children) => (
              <Heading size="h3" className="text-mkNavy-400">
                {children}
              </Heading>
            ),

            /**
             * @param {object} _ - Node Data
             * @param {React.ReactNode} children - Node Children Tree
             * @returns {React.ReactNode} - ReactNode
             */
            [BLOCKS.HEADING_2]: (_, children) => (
              <Heading
                size="h4"
                className="text-mkNavy-400 mb-3 mt-6 md:mt-8 md:mb-4 last:mb-0"
              >
                {children}
              </Heading>
            ),

            /**
             * @param {object} _ - Node Data
             * @param {React.ReactNode} children - Node Children Tree
             * @returns {React.ReactNode} - ReactNode
             */
            [BLOCKS.HEADING_3]: (_, children) => (
              <Heading size="h5" className="text-mkNavy-400">
                {children}
              </Heading>
            ),

            /**
             * @param {object} _ - Node Data
             * @param {React.ReactNode} children - Node Children Tree
             * @returns {React.ReactNode} - ReactNode
             */
            [BLOCKS.HEADING_4]: (_, children) => (
              <Heading size="h6" className="text-mkNavy-400">
                {children}
              </Heading>
            ),

            /**
             * @param {object} _ - Node Data
             * @param {React.ReactNode} children - Node Children Tree
             * @returns {React.ReactNode} - ReactNode
             */
            [BLOCKS.HEADING_5]: (_, children) => (
              <Heading size="h6" className="text-mkNavy-400">
                {children}
              </Heading>
            ),

            /**
             * @param {object} _ - Node Data
             * @param {React.ReactNode} children - Node Children Tree
             * @returns {React.ReactNode} - ReactNode
             */
            [BLOCKS.HEADING_6]: (_, children) => (
              <Heading size="h6" className="text-mkNavy-400">
                {children}
              </Heading>
            ),

            /**
             * @param {object} _ - Node Data
             * @param {React.ReactNode} children - Node Children Tree
             * @returns {React.ReactNode} - ReactNode
             */
            [BLOCKS.PARAGRAPH]: (_, children) => {
              const textStyle = {
                fontSize: props.fields.contentTextSize ?? 'inherit',
                textAlign: props.fields.contentTextAlignment ?? undefined,
                color: props.fields.contentColor ?? 'inherit',
              };
              return (
                <p style={textStyle} className="text-body-article mb-8">
                  {children}
                </p>
              );
            },

            /**
             * @param {object} _ - Node Data
             * @param {React.ReactNode} children - Node Children Tree
             * @returns {React.ReactNode} - ReactNode
             */
            [BLOCKS.LIST_ITEM]: (_, children) => (
              <li className="list-disc list-outside mb-[18px] ml-7">
                {children}
              </li>
            ),

            /**
             * @param {object} _ - Node Data
             * @param {React.ReactNode} children - Node Children Tree
             * @returns {React.ReactNode} - ReactNode
             */
            [BLOCKS.QUOTE]: (_, children) => (
              <AnimateItem
                delay={0}
                className="sm:grid sm:grid-cols-6 sm:gap-6 md:grid-cols-8 md:gap-8"
              >
                <Quote
                  text={children as string}
                  classes="mx-auto [&>span>h2>p]:m-0 [&>span>h2.font-light]:font-normal sm:col-span-4 sm:col-start-2 md:col-span-6 md:col-start-2 mb-8"
                />
              </AnimateItem>
            ),

            /**
             * @param {object} node - Node Data
             * @returns {React.ReactNode} - ReactNode
             */
            [BLOCKS.EMBEDDED_ASSET]: (node) => (
              <AnimateItem delay={0}>
                <div className="flex items-center justify-center my-8 md:my-12">
                  <ContentfulImage
                    className="rounded-3xl"
                    width={node.data.target.fields.file.details.image.width}
                    height={node.data.target.fields.file.details.image.height}
                    alt={node.data.target.fields.title}
                    src={addHttps(node.data.target.fields.file.url)}
                  />
                </div>
              </AnimateItem>
            ),
            /**
             * @param {object} node - Node Data
             * @param {object} node.data - Node Data
             * @param {React.ReactNode} children - Node Children Tree
             * @returns {React.ReactNode | void} - ReactNode
             */
            [INLINES.HYPERLINK]: ({ data }, children) => {
              // Only process youtube links
              if (
                data.uri &&
                (data.uri.includes('youtube.com') ||
                  data.uri.includes('youtu.be'))
              ) {
                return youtubeParser(data.uri);
              } else {
                return anchorTarget(data.uri, children);
              }
            },
          },
        })}
      </div>
    </div>
  );
};
